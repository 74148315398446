/** Homepage */

'use strict'

$(document).ready(function () {
  // Location Carousel - Slick Initialization
  $('.location__carousel').slick({
    centerMode: false,
    dots: true,
    arrows: true,
    gap: 24,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow: $('.slick__prev--location'),
    nextArrow: $('.slick__next--location'),
    appendDots: $('.slick__dots--location'),
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  // Location Carousel - Slick Initialization
  $('.location__carousel--v3').slick({
    centerMode: false,
    dots: true,
    arrows: false,
    gap: 40,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    appendDots: $('.slick__dots--location'),
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });

  //Testimonial Carousel - Slick Initialization
  let testimonialCarouselNav = $('.testimonials__counter');
  let testimonialCarousel = $('.testimonials__carousel');

  testimonialCarousel.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    // If currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
    let currentSlideNumber = (currentSlide ? currentSlide : 0) + 1;

    // Update current active slide number of navigation
    testimonialCarouselNav.html(currentSlideNumber + '/' + '<span>' + slick.slideCount + '</span>');
  });

  testimonialCarousel.slick({
    centerMode: false,
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.slick__prev--testimonials'),
    nextArrow: $('.slick__next--testimonials')
  });


  // Trigger play/pause testimonial video on play/pause button clicked
  $('.video__buttons').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let videoID = '#' + $(this).siblings('video').attr('id');

    if ($(this).hasClass('pause')) {
      // Play the video
      $(videoID).get(0).play();

      // Update the button icon to 'Pause' icon
      $(this).removeClass('pause');
      $(this).addClass('play');
      $(this).find('.play').addClass('d-none');
      $(this).find('.pause').removeClass('d-none');
    } else {
      // Pause the video
      $(videoID).get(0).pause();

      // Update the button icon to 'Play' icon
      $(this).addClass('pause');
      $(this).removeClass('play');
      $(this).find('.pause').addClass('d-none');
      $(this).find('.play').removeClass('d-none');
    }
  });
});